import { render, staticRenderFns } from "./CoreBlockArticles.vue?vue&type=template&id=77d84f6a&scoped=true"
import script from "./CoreBlockArticles.vue?vue&type=script&lang=js"
export * from "./CoreBlockArticles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d84f6a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default})
