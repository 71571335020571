
import { computed } from "vue";

export default {
	name: "CoreBlockArticles",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const containerType = computed(
			() => props.settings?.["container-type"]
		);
		const containerVariants = computed(() => {
			return containerType.value === "container"
				? { ...props.settings?.variants, layout: "carousel" }
				: { ...props.settings?.variants };
		});
		const title = computed(() => props.settings?.title);
		const sortBy = computed(() => props.settings?.sortBy);

		const articleFields = computed(() => ({
			showMedia: props.settings?.showMedia,
			showDatePublished: props.settings?.showDatePublished,
			showHeadline: props.settings?.showHeadline,
			showBody: props.settings?.showBody,
		}));

		const itemsAdapter = (item) => {
			if (!item) return item;
			switch (item.type) {
				case "image":
				case "video":
					return {
						id: item.id,
						type: item.type,
						src: {
							src: item?.presetSettings?.url,
							alt: item?.presetSettings?.altText,
						},
					};
				case "article":
					return { ...item, ...articleFields.value };
				default:
					return item;
			}
		};

		const items = computed(() => {
			if (!Array.isArray(props.settings?.items)) return [];
			return props.settings?.items.map(itemsAdapter);
		});

		const getSettings = computed(() => ({
			...props.settings,
			id: `${props.settings.id}a`,
			items: null,
			type: containerType.value,
			variants: containerVariants.value,
			blocks: items.value,
		}));

		return {
			containerType,
			containerVariants,
			title,
			items,
			sortBy,
			articleFields,
			getSettings,
		};
	},
};
